import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Collapse,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createRevision } from "../../../../libraries/enums/permissionRights";
import {
  selectFlattenUnits,
  selectRevisionCompanies,
  selectRevisionSubjectById,
  selectRevisionSubjects,
} from "../../../enum/selectors/enumSelectors";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import revisionSlice from "../../slices/revisionSlice";
import {
  selectSelectedRevision,
  selectSelectedRevisionProperty,
} from "../../selectors/revisionSelectors";
import {
  getRevisionTypes,
  REVISION_TYPE_CODE__regular,
} from "../../../../libraries/enums/revisionTypes";
import {
  BrandType,
  IssueAttachmentInputType,
  Nullable,
  RevisionType,
} from "../../../../types";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { formatAddress } from "../../../common/utils/address.utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDropzone } from "react-dropzone";
import { grey } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "@mui/icons-material";
import {
  addIntervalToDate,
  formatDate,
  getDateIntervalDescription,
} from "../../../common/utils/dateTime.utils";
import { revisionTry } from "../../../../api/api";
import {
  getActionOfRevisionSubject,
  getFullTitleOfRevisionSubject,
} from "../../../../libraries/enums/revisionSubjects";
import { REVISION_STATUS_CODE__valid } from "../../../../libraries/enums/revisionStatuses";

const RevisionDetailFieldRevisionType = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "typeId",
        value: value,
      })
    );
  };
  const value = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "typeId" })
  );

  const options = getRevisionTypes();

  useEffect(() => {
    if (options.length === 1) {
      changeValue(options[0].code);
    }
  }, [options]);

  if (options.length === 1) {
    return <></>;
  }

  return (
    <RadioGroup
      value={value}
      onChange={(event) => {
        changeValue(event.target.value);
      }}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.code}
          value={option.code}
          control={<Radio color="primary" />}
          label={option.label}
          sx={{ mx: 2 }}
        />
      ))}
    </RadioGroup>
  );
};

const RevisionDetailFieldUnitStoreNumber = () => {
  const dispatch = useAppDispatch();

  const changeUnitStoreNumber = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "unitStoreNumber",
        value: value,
      })
    );
  };

  const unitStoreNumber = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "unitStoreNumber" })
  );
  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  useEffect(() => {
    if (unitOptions.length === 1) {
      changeUnitStoreNumber(unitOptions[0].storeNumber);
    }
  }, [unitOptions]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeUnitStoreNumber(newValue.storeNumber);
        } else {
          changeUnitStoreNumber(null);
        }
      }}
      value={unitOptions.find((u) => u.storeNumber === unitStoreNumber) || null}
      options={unitOptions}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.storeNumber} {...rest}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => <TextField {...params} label={"Středisko"} />}
    />
  );
};

const RevisionDetailFieldRevisionAt = () => {
  const dispatch = useAppDispatch();

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "revisionAt",
        value: !!value ? value.toISOString() : value,
      })
    );
  };
  const value = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "revisionAt" })
  );

  return (
    <DatePicker
      label="Datum provedení revize"
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(params: any) => <TextField fullWidth {...params} />}
      disableFuture={true}
    />
  );
};

const RevisionDetailFieldRevisionSubject = () => {
  const dispatch = useAppDispatch();

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "revisionSubjectId",
        value: value,
      })
    );
  };

  const revisionSubjectId = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "revisionSubjectId" })
  );
  const options = useAppSelector(selectRevisionSubjects);

  const getOptionAction = (option: any) => {
    return getActionOfRevisionSubject(option);
  };

  const getOptionTitle = (option: any) => {
    return getFullTitleOfRevisionSubject(option);
  };

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.id);
        } else {
          setValue(null);
        }
      }}
      value={options.find((u) => u.id === revisionSubjectId) || null}
      options={options}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            {getOptionTitle(option)}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return getOptionAction(option);
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) => getOptionTitle(option)}
      renderInput={(params) => (
        <TextField {...params} label={"Předmět revize"} />
      )}
    />
  );
};

const RevisionDetailFieldWithDefects = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "withDefects",
        value: value,
      })
    );
  };
  const value = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "withDefects" })
  );

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            color={"primary"}
            checked={value}
            onChange={(event) => {
              changeValue(event.target.checked);
            }}
          />
        }
        label={"Při revizi byly zjištěny závady"}
      />
    </FormGroup>
  );
};

const RevisionDetailFieldDefects = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "defects",
        value: value,
      })
    );
  };
  const value = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "defects" })
  );

  const withDefects = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "withDefects" })
  );

  return (
    <Collapse in={withDefects}>
      <TextField
        sx={{ mt: 2 }}
        fullWidth={true}
        value={value}
        onChange={(event) => {
          changeValue(event.target.value);
        }}
        multiline={true}
        rows={3}
        label={"Popis závady"}
      />
    </Collapse>
  );
};

const RevisionDetailFieldRevisionCompany = () => {
  const dispatch = useAppDispatch();

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "revisionCompanyId",
        value: value,
      })
    );
  };

  const revisionCompanyId = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "revisionCompanyId" })
  );
  const options = useAppSelector(selectRevisionCompanies);

  useEffect(() => {
    if (options.length === 1) {
      setValue(options[0].id);
    }
  }, [options]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.id);
        } else {
          setValue(null);
        }
      }}
      value={options.find((u) => u.id === revisionCompanyId) || null}
      options={options}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            {option.title}
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} label={"Revizní společnost"} />
      )}
    />
  );
};

const RevisionDetailFieldNextRevisionAt = () => {
  const dispatch = useAppDispatch();

  const setValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "nextRevisionAt",
        value: !!value ? value.toISOString() : value,
      })
    );
  };
  const value = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "nextRevisionAt" })
  );

  const typeId = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "typeId" })
  );
  const revisionAt = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "revisionAt" })
  );
  const nextRevisionAt = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "nextRevisionAt" })
  );
  const revisionSubjectId = useAppSelector((state) =>
    selectSelectedRevisionProperty(state, { property: "revisionSubjectId" })
  );
  const revisionSubject = useAppSelector((state) =>
    selectRevisionSubjectById(state, revisionSubjectId)
  );

  const [helperText, setHelperText] = useState<Nullable<string>>(null);

  useEffect(() => {
    let ht = null;
    if (
      !!revisionSubject &&
      revisionSubject.validity !== "" &&
      typeId === REVISION_TYPE_CODE__regular
    ) {
      ht =
        getFullTitleOfRevisionSubject(revisionSubject) +
        " má platnost " +
        getDateIntervalDescription(revisionSubject.validity) +
        ".";
      if (!!revisionAt) {
        const guessedRevisionAt = addIntervalToDate(
          revisionAt,
          revisionSubject.validity
        );
        ht =
          ht +
          " Datum příští revize by mělo být " +
          formatDate(guessedRevisionAt.toISOString(), "d. L. yyyy") +
          ".";
        if (!nextRevisionAt) {
          setValue(guessedRevisionAt);
        }
      }
    }
    setHelperText(ht);
  }, [revisionAt, revisionSubjectId, revisionSubject, typeId]);

  return (
    <DatePicker
      label="Datum příští revize"
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(params: any) => (
        <TextField fullWidth helperText={helperText} {...params} />
      )}
    />
  );
};

type FileEnvelopeType = {
  name: string;
  file: File;
  preview: string | undefined;
};

const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const RevisionDetailFieldFile = () => {
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<FileEnvelopeType[]>([]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => {
        return acceptedFiles
          .filter((acceptedFile) => {
            return !prev.map((p) => p.name).includes(acceptedFile.name);
          })
          .map((acceptedFile) => {
            return {
              name: acceptedFile.name,
              file: acceptedFile,
              preview: URL.createObjectURL(acceptedFile),
            };
          });
      });
    },
  });

  const removeFile = (fileName: string) => {
    setFiles((prev) => {
      const index = prev.findIndex(
        (p: FileEnvelopeType) => p.name === fileName
      );
      if (index > -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  const changeValue = (value: any) => {
    dispatch(
      revisionSlice.actions.selectedRevisionPropertySet({
        property: "fileContent",
        value: value,
      })
    );
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    const update = async (files: FileEnvelopeType[]) => {
      changeValue(null);
      const value: IssueAttachmentInputType[] = [];
      for (const fe of files) {
        const base64 = await toBase64(fe.file);
        value.push(base64);
      }
      changeValue(value[0]);
    };

    update(files);
  }, [files]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {files.map((file: FileEnvelopeType) => {
          return (
            <Box
              key={file.name}
              sx={{
                mb: 1,
                mr: 1,
                bgcolor: grey[100],
                p: 1,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  borderRadius: "4px",
                  width: 114,
                  height: 114,
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{}}>
                  {file.file.type.startsWith("image") ? (
                    <>
                      <img
                        alt={file.name}
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100%",
                          objectPosition: "center",
                          objectFit: "cover",
                          borderRadius: 4,
                        }}
                        src={file.preview}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          if (file.preview != null) {
                            URL.revokeObjectURL(file.preview);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Box>{file.name}</Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    removeFile(file.name);
                  }}
                  size={"small"}
                  color={"error"}
                  variant="text"
                  startIcon={<DeleteIcon />}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  Odstranit
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={open}
          startIcon={<FileUpload />}
        >
          Nahrát revizní list
        </Button>
      </div>
    </>
  );
};

const RevisionDetailFieldInfoRevisionSubject = (props: {
  revisionSubjectId: string;
}) => {
  const revisionSubject = useAppSelector((state) =>
    selectRevisionSubjectById(state, props.revisionSubjectId)
  );

  if (!revisionSubject) {
    return <></>;
  }

  return <>{getFullTitleOfRevisionSubject(revisionSubject)}</>;
};

const RevisionDetailFieldInfo = () => {
  const revision = useAppSelector(selectSelectedRevision);

  const [lastRevision, setLastRevision] =
    useState<Nullable<RevisionType>>(null);

  const collapseIn =
    !!revision &&
    !!lastRevision &&
    lastRevision.typeId === REVISION_TYPE_CODE__regular &&
    lastRevision.statusId === REVISION_STATUS_CODE__valid;

  useEffect(() => {
    if (
      !!revision &&
      !!revision.typeId &&
      !!revision.unitStoreNumber &&
      !!revision.revisionSubjectId &&
      !!revision.revisionAt
    ) {
      revisionTry({ data: revision }).then((response) => {
        // @ts-ignore
        setLastRevision(response.data.lastRevision);
      });
    }
  }, [revision]);

  return (
    <Collapse in={collapseIn}>
      <Alert severity={"info"}>
        {collapseIn && lastRevision.revisionAt <= revision.revisionAt && (
          <>
            Poslední{" "}
            <strong>
              <RevisionDetailFieldInfoRevisionSubject
                revisionSubjectId={revision.revisionSubjectId}
              />
            </strong>{" "}
            na středisku <strong>{revision.unitStoreNumber}</strong> je ze dne{" "}
            {formatDate(lastRevision.revisionAt, "d. L. yyyy")}. Vytvořením nové
            revize s datem {formatDate(revision.revisionAt, "d. L. yyyy")} tato
            poslední revize <strong>bude nahrazena</strong>.
          </>
        )}
        {collapseIn && lastRevision.revisionAt > revision.revisionAt && (
          <>
            Poslední{" "}
            <strong>
              <RevisionDetailFieldInfoRevisionSubject
                revisionSubjectId={revision.revisionSubjectId}
              />
            </strong>{" "}
            na středisku <strong>{revision.unitStoreNumber}</strong> je ze dne{" "}
            {formatDate(lastRevision.revisionAt, "d. L. yyyy")}. Vytvořením nové
            revize s datem {formatDate(revision.revisionAt, "d. L. yyyy")} tato
            poslední revize <strong>nebude nahrazena</strong>.
          </>
        )}
      </Alert>
    </Collapse>
  );
};

const RevisionDetailModeUpdate = (props: any) => {
  const { handleSubmit } = props;

  const rightCreateRevision = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createRevision,
    })
  );
  const unitOptions = useAppSelector(selectFlattenUnits);

  if (!rightCreateRevision) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ mb: 2 }} hidden={unitOptions.length === 1}>
        <RevisionDetailFieldUnitStoreNumber />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldRevisionType />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldRevisionCompany />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldRevisionSubject />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldRevisionAt />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldNextRevisionAt />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldWithDefects />
        <RevisionDetailFieldDefects />
      </Box>
      <Box sx={{ mb: 2 }}>
        <RevisionDetailFieldFile />
      </Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <RevisionDetailFieldInfo />
      </Box>
    </>
  );
};

export default RevisionDetailModeUpdate;
