import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createDocument } from "../../../../libraries/enums/permissionRights";
import {
  selectFlattenUnits,
  selectSelectedUnitId,
} from "../../../enum/selectors/enumSelectors";
import { BrandType, IssueAttachmentInputType } from "../../../../types";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import { formatAddress } from "../../../common/utils/address.utils";
import { useDropzone } from "react-dropzone";
import { grey } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUpload } from "@mui/icons-material";
import documentSlice from "../../slices/document.slice";
import { selectSelectedDocumentProperty } from "../../selectors/document.selectors";

const DocumentDetailFieldUnit = () => {
  const dispatch = useAppDispatch();

  const selectedUnitId = useAppSelector((state) => selectSelectedUnitId(state));

  const changeUnitId = (value: any) => {
    dispatch(
      documentSlice.actions.selectedDocumentPropertySet({
        property: "unitId",
        value: value,
      })
    );
  };

  const unitId = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "unitId" })
  );
  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  useEffect(() => {
    if (!unitId) {
      if (unitOptions.length === 1) {
        changeUnitId(unitOptions[0].id);
      }
      if (!!selectedUnitId) {
        changeUnitId(selectedUnitId);
      }
    }
  }, [unitOptions, selectedUnitId, unitId]);

  return (
    <Autocomplete
      readOnly={!!selectedUnitId}
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeUnitId(newValue.id);
        } else {
          changeUnitId(null);
        }
      }}
      value={unitOptions.find((u) => u.id === unitId) || null}
      options={unitOptions}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.storeNumber} {...rest}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => <TextField {...params} label={"Středisko"} />}
    />
  );
};

const DocumentDetailFieldIsNotNecessary = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      documentSlice.actions.selectedDocumentPropertySet({
        property: "isNotNecessary",
        value: value,
      })
    );
  };

  const value = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "isNotNecessary" })
  );

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              color={"secondary"}
              checked={!!value}
              onChange={(event) => {
                changeValue(event.target.checked);
              }}
            />
          }
          label="Dokument není potřeba"
        />
      </FormGroup>
      {!!value && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Jste si opravdu jistí, že dokument není potřeba? Nepoužívejte tuto
          možnost, pokud dokument není k nalezení.
        </Alert>
      )}
    </>
  );
};

const DocumentDetailFieldTitle = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      documentSlice.actions.selectedDocumentPropertySet({
        property: "title",
        value: value,
      })
    );
  };

  const value = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "title" })
  );

  const templateId = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "templateId" })
  );

  return (
    <TextField
      label={"Název"}
      fullWidth={true}
      value={value}
      disabled={!!templateId ? true : false}
      InputProps={{
        readOnly: !!templateId,
      }}
      onChange={(event) => {
        if (!templateId) {
          changeValue(event.target.value);
        }
      }}
    />
  );
};

const DocumentDetailFieldNote = () => {
  const dispatch = useAppDispatch();

  const changeValue = (value: any) => {
    dispatch(
      documentSlice.actions.selectedDocumentPropertySet({
        property: "note",
        value: value,
      })
    );
  };

  const value = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "note" })
  );

  return (
    <TextField
      label={"Poznámka"}
      fullWidth={true}
      value={value}
      onChange={(event) => {
        changeValue(event.target.value);
      }}
    />
  );
};

type FileEnvelopeType = {
  name: string;
  file: File;
  preview: string | undefined;
};

const toBase64 = (file: File): any =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const DocumentDetailFieldFile = () => {
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<FileEnvelopeType[]>([]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prev) => {
        return acceptedFiles
          .filter((acceptedFile) => {
            return !prev.map((p) => p.name).includes(acceptedFile.name);
          })
          .map((acceptedFile) => {
            return {
              name: acceptedFile.name,
              file: acceptedFile,
              preview: URL.createObjectURL(acceptedFile),
            };
          });
      });
    },
  });

  const removeFile = (fileName: string) => {
    setFiles((prev) => {
      const index = prev.findIndex(
        (p: FileEnvelopeType) => p.name === fileName
      );
      if (index > -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  const changeValue = (value: any) => {
    dispatch(
      documentSlice.actions.selectedDocumentPropertySet({
        property: "fileContent",
        value: value,
      })
    );
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    const update = async (files: FileEnvelopeType[]) => {
      changeValue(null);
      const value: IssueAttachmentInputType[] = [];
      for (const fe of files) {
        const base64 = await toBase64(fe.file);
        value.push(base64);
      }
      changeValue(value[0]);
    };

    update(files);
  }, [files]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {files.map((file: FileEnvelopeType) => {
          return (
            <Box
              key={file.name}
              sx={{
                mb: 1,
                mr: 1,
                bgcolor: grey[100],
                p: 1,
                borderRadius: "4px",
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  borderRadius: "4px",
                  width: 114,
                  height: 114,
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{}}>
                  {file.file.type.startsWith("image") ? (
                    <>
                      <img
                        alt={file.name}
                        style={{
                          display: "block",
                          width: "100%",
                          height: "100%",
                          objectPosition: "center",
                          objectFit: "cover",
                          borderRadius: 4,
                        }}
                        src={file.preview}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          if (file.preview != null) {
                            URL.revokeObjectURL(file.preview);
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Box>{file.name}</Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    removeFile(file.name);
                  }}
                  size={"small"}
                  color={"error"}
                  variant="text"
                  startIcon={<DeleteIcon />}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  Odstranit
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={open}
          startIcon={<FileUpload />}
        >
          Nahrát soubor
        </Button>
      </div>
    </>
  );
};

const DocumentDetailModeUpdate = () => {
  const rightCreateDocument = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight: PERMISSION_RIGHT_CODE__createDocument,
    })
  );
  const unitOptions = useAppSelector(selectFlattenUnits);
  const selectedUnitId = useAppSelector(selectSelectedUnitId);
  const templateId = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "templateId" })
  );
  const isNotNecessary = useAppSelector((state) =>
    selectSelectedDocumentProperty(state, { property: "isNotNecessary" })
  );

  if (!rightCreateDocument) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ mb: 2 }} hidden={!templateId}>
        <DocumentDetailFieldIsNotNecessary />
      </Box>
      <Box sx={{ mb: 2 }} hidden={!!isNotNecessary || unitOptions.length === 1}>
        <DocumentDetailFieldUnit />
      </Box>
      <Box sx={{ mb: 2 }} hidden={!!isNotNecessary}>
        <DocumentDetailFieldTitle />
      </Box>
      <Box sx={{ mb: 2 }} hidden={!!isNotNecessary}>
        <DocumentDetailFieldNote />
      </Box>
      <Box sx={{ mb: 2 }} hidden={!!isNotNecessary}>
        <DocumentDetailFieldFile />
      </Box>
    </>
  );
};

export default DocumentDetailModeUpdate;
