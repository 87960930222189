import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { selectServiceCompanies } from "../../../../enum/selectors/enumSelectors";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import issueSlice from "../../../slices/issueSlice";
import { serviceCompaniesByIssuesCountGet } from "../../../../../api/api";

const IssueDetailFieldServiceCompanyEditable = () => {
  const dispatch = useAppDispatch();

  const unitId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitId" })
  );

  const [serviceCompaniesByIssuesCount, setServiceCompaniesByIssuesCount] =
    useState<any>([]);

  const changeValueId = (value: any) => {
    dispatch(
      issueSlice.actions.selectedIssuePropertiesSet([
        {
          property: "serviceCompanyId",
          value: value,
        },
        { property: "sentToServiceCompanyAt", value: null },
        { property: "attachmentInputs", value: [] },
      ])
    );
  };

  const valueId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "serviceCompanyId" })
  );
  const serviceCompanies = useAppSelector((state) =>
    selectServiceCompanies(state)
  );

  const options = serviceCompaniesByIssuesCount
    .filter((x: any) => {
      return x.c >= 1;
    })
    .map((x: any) => {
      const index = serviceCompanies.findIndex(
        (sc) => x.serviceCompanyId === sc.id
      );
      return {
        ...serviceCompanies[index],
        group: "Nejčastější",
        count: x.c,
      };
    })
    .concat(
      serviceCompanies.map((sc) => {
        return { ...sc, group: "Ostatní", count: -1 };
      })
    );

  useEffect(() => {
    serviceCompaniesByIssuesCountGet({ unitId: unitId }).then((response) => {
      if (response.status === 200) {
        // @ts-ignore
        setServiceCompaniesByIssuesCount(response.data);
      }
    });
  }, [unitId]);

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        if (!!newValue) {
          changeValueId(newValue.id);
        } else {
          changeValueId(null);
        }
      }}
      value={options.find((u: any) => u.id === valueId)}
      options={[...options].sort((a, b) => {
        // Nejprve porovnání podle 'group'
        if (a.group === "Nejčastější" && b.group !== "Nejčastější") {
          return -1;
        } else if (a.group !== "Nejčastější" && b.group === "Nejčastější") {
          return 1;
        }
        // Pokud jsou stejné, pak porovnání podle 'count' sestupně
        if (a.count !== b.count) {
          return b.count - a.count;
        }
        // Pokud jsou stejné, pak porovnání podle 'title' vzestupně
        return a.title.localeCompare(b.title);
      })}
      groupBy={
        serviceCompaniesByIssuesCount.length > 0
          ? (option) => option.group
          : undefined
      }
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} label={"Servisní firma"} size="small" />
      )}
    />
  );
};

const IssueDetailFieldServiceCompany = () => {
  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return (
        <>
          <IssueDetailFieldServiceCompanyEditable />
        </>
      );
    }
    case "update": {
      return (
        <>
          <IssueDetailFieldServiceCompanyEditable />
        </>
      );
    }
    default: {
      //read
      return (
        <>
          <IssueDetailFieldServiceCompanyEditable />
        </>
      );
    }
  }
};

export default IssueDetailFieldServiceCompany;
