import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Copyright from "../Copyright/Copyright";
import { Container } from "@mui/system";
import { ChevronLeft } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { ROUTER_PATH_INDEX } from "../../../../app/router";
import { grey } from "@mui/material/colors";

type LayoutProps = {
  children: React.ReactNode;
};

const LayoutHome = (props: LayoutProps) => {
  const location = useLocation();

  return (
    <>
      <Container component="main" maxWidth="sm" sx={{ mt: 8 }}>
        <CssBaseline />

        <Box p={3}>
          <Typography
            color={"primary"}
            component="h1"
            variant="h3"
            fontWeight={"500"}
            mb={2}
            textAlign="center"
          >
            Fixnote
          </Typography>
        </Box>

        <Paper
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            mb: 3,
            px: 3,
            pb: 3,
          }}
          elevation={0}
        >
          {location.pathname !== ROUTER_PATH_INDEX && (
            <Box sx={{ mt: 3 }}>
              <Link to={ROUTER_PATH_INDEX} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItem: "center",
                    color: grey[500],
                    zoom: 0.85,
                  }}
                >
                  <ChevronLeft />
                  <Box sx={{ fontWeight: 500 }}>Zpět</Box>
                </Box>
              </Link>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>{props.children}</Box>
        </Paper>
        <Copyright />
      </Container>
    </>
  );
};

export default LayoutHome;
