import { Clear, FilterList } from "@mui/icons-material";
import React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Popover,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { buildInitialFilter } from "../../builders/filter.builder";
import { selectFlattenUnits } from "../../../enum/selectors/enumSelectors";
import { formatAddress } from "../../../common/utils/address.utils";
import { BrandType } from "../../../../types";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import { useTheme } from "@mui/material/styles";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import {
  selectUnitFilterIsEnabled,
  selectUnitFilterProperty,
} from "../../selectors/unit.selector";
import unitSlice from "../../slices/unit.slice";

const UnitFilterContentUnit = () => {
  const property = "unitIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectUnitFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      unitSlice.actions.unitFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={unitOptions.filter((u) => value.includes(u.id))}
      options={unitOptions}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {/* {formatAddress(option.address)} */}
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => (
        <TextField {...params} margin="normal" label={"Středisko"} />
      )}
    />
  );
};

const UnitFilterContent = () => {
  const profile = useAppSelector(selectProfile);

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <UnitFilterContentUnit />
    </>
  );
};

const UnitFilter = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const filterEnabled = useAppSelector(selectUnitFilterIsEnabled);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    dispatch(unitSlice.actions.unitFilterSet(buildInitialFilter()));
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ButtonGroup
        orientation={
          useMediaQuery(theme.breakpoints.up("md")) ? "horizontal" : "vertical"
        }
        fullWidth={useMediaQuery(theme.breakpoints.up("md")) ? false : true}
      >
        <Button
          onClick={handleOpen}
          variant={"outlined"}
          color={filterEnabled ? "warning" : "primary"}
          startIcon={<FilterList />}
        >
          Filtrovat
        </Button>
        <Button
          onClick={() => {
            handleReset();
          }}
          variant={"outlined"}
          disabled={!filterEnabled}
          startIcon={<Clear />}
        >
          Zrušit filtr
        </Button>
      </ButtonGroup>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            p: 2,
            background: (theme) => `${theme.palette.background.default}`,
            width: {
              xs: "100%",
              md: "40%",
            },
          },
        }}
      >
        <CloseButton onClick={handleClose} />

        <Button
          onClick={() => {
            handleReset();
          }}
          size={"small"}
        >
          Zrušit filtr
        </Button>

        <Divider sx={{ mt: 1, mb: 1 }} />

        <UnitFilterContent />
      </Popover>
    </>
  );
};

export default UnitFilter;
