import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  selectSelectedIssueMode,
  selectSelectedIssueProperty,
} from "../../../selectors/issueSelectors";
import SendIcon from "@mui/icons-material/Send";
import issueSlice from "../../../slices/issueSlice";
import { selectServiceTechnicians } from "../../../../enum/selectors/enumSelectors";
import CloseButton from "../../../../common/components/CloseButton/CloseButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

type ServiceTechnicianOptionType = {
  inputValue?: string;
  id?: string;
  email: string;
};

const filter = createFilterOptions<ServiceTechnicianOptionType>();

const IssueDetailFieldServiceTechnicianEditable = () => {
  const dispatch = useAppDispatch();

  const serviceTechnicianId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, {
      property: "serviceTechnicianId",
    })
  );
  const serviceCompanyId = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "serviceCompanyId" })
  );

  const serviceTechnicians = useAppSelector((state) =>
    selectServiceTechnicians(state)
  );

  const options: ServiceTechnicianOptionType[] = serviceTechnicians
    .filter((st) => {
      return st.serviceCompanyId === serviceCompanyId;
    })
    .map((st) => {
      return {
        id: st.id,
        email: st.title,
      };
    });

  const [value, setValue] = useState<ServiceTechnicianOptionType | null>(null);
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({ email: "" });

  const handleDialogClose = () => {
    setDialogValue({
      email: "",
    });
    setOpen(false);
  };
  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogSubmit = (event: any) => {
    event.preventDefault();
    setValue({
      email: dialogValue.email,
    });
    handleDialogClose();
  };

  const handleSubmit = () => {
    if (!!value) {
      dispatch(
        issueSlice.actions.selectedIssuePropertiesSet([
          {
            property: "sentToServiceTechnicianAt",
            value: new Date().toISOString(),
          },
          { property: "serviceTechnicianInput", value: value },
          { property: "attachmentInputs", value: [] },
        ])
      );
    }
  };

  useEffect(() => {
    if (!!serviceTechnicianId) {
      const index = options.findIndex((o) => o.id === serviceTechnicianId);
      if (index > -1) {
        setValue(options[index]);
      } else {
        setValue(null);
      }
    }
  }, []); //chci, aby effect byl spuštěn pouze 1x

  return (
    <>
      <Autocomplete
        clearOnBlur
        freeSolo
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              handleDialogOpen();
              setDialogValue({ email: newValue });
            });
          } else if (newValue && newValue.inputValue) {
            handleDialogOpen();
            setDialogValue({ email: newValue.inputValue });
          } else {
            setValue(newValue);
          }
        }}
        value={value}
        options={options}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              email: `Založit nového servisního technika: "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderOption={(props, option) => {
          const { key, ...rest } = props;
          return (
            <li key={option.id} {...rest}>
              <strong>{option.email}</strong>
            </li>
          );
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.email;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"E-mailová adresa technika"}
            size="small"
          />
        )}
      />
      {!!value && (serviceTechnicianId || -1) !== (value?.id || -2) && (
        <Button
          onClick={handleSubmit}
          sx={{ mt: 2, mb: 2 }}
          size={"small"}
          variant={"outlined"}
          color={"secondary"}
          startIcon={<SendIcon />}
        >
          Odeslat servisnímu technikovi
        </Button>
      )}
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="xs">
        <DialogTitle>
          Založit nového servisního technika
          <CloseButton onClick={handleDialogClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Nenašli jste svého servisního technika v seznamu? Založte jej,
            prosím.
          </DialogContentText>

          <TextField
            value={dialogValue.email}
            margin="normal"
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                email: event.target.value,
              })
            }
            label="E-mailová adresa"
            type="text"
            fullWidth
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDialogSubmit}
            startIcon={<PersonAddIcon />}
          >
            Založit technika
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const IssueDetailFieldServiceTechnician = () => {
  const mode = useAppSelector(selectSelectedIssueMode);

  switch (mode) {
    case "create": {
      return (
        <>
          <IssueDetailFieldServiceTechnicianEditable />
        </>
      );
    }
    case "update": {
      return (
        <>
          <IssueDetailFieldServiceTechnicianEditable />
        </>
      );
    }
    default: {
      //read
      return (
        <>
          <IssueDetailFieldServiceTechnicianEditable />
        </>
      );
    }
  }
};

export default IssueDetailFieldServiceTechnician;
