import { Clear, FilterList } from "@mui/icons-material";
import React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Popover,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import issueSlice from "../../slices/issueSlice";
import {
  selectIssueFilterIsEnabled,
  selectIssueFilterProperty,
} from "../../selectors/issueSelectors";
import {
  getIssueStatusByCode,
  ISSUE_STATUS_CODE__inProgress,
  ISSUE_STATUS_CODE__new,
} from "../../../../libraries/enums/issueStatuses";
import {
  getIssueTypes,
  ISSUE_TYPE_CODE__crash,
} from "../../../../libraries/enums/issueTypes";
import { buildInitialFilter } from "../../builders/filter.builder";
import {
  selectFlattenUnits,
  selectServiceCompanies,
  selectServiceTechnicians,
} from "../../../enum/selectors/enumSelectors";
import { formatAddress } from "../../../common/utils/address.utils";
import { BrandType } from "../../../../types";
import { getBrandLogoUrlByBrandCode } from "../../../../libraries/brands";
import WarningIcon from "@mui/icons-material/Warning";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import { useTheme } from "@mui/material/styles";
import {
  ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany,
  ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit,
} from "../../../../libraries/enums/issueProgressItemTypes";
import { selectProfile } from "../../../auth/selectors/authSelectors";
import { USER_TYPE_CODE__headquarters } from "../../../../libraries/enums/userTypes";

const IssueFilterContentDateFrom = () => {
  const property = "dateFrom";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: !!value ? value.toISOString() : value,
      })
    );
  };

  return (
    <DatePicker
      label="Datum vytvoření od"
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(params: any) => <TextField fullWidth {...params} />}
    />
  );
};

const IssueFilterContentDateTo = () => {
  const property = "dateTo";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: !!value ? value.toISOString() : value,
      })
    );
  };

  return (
    <DatePicker
      label="Datum vytvoření do"
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(params: any) => <TextField fullWidth {...params} />}
    />
  );
};

const IssueFilterContentNumber = () => {
  const property = "number";
  const dispatch = useAppDispatch();

  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <TextField
      type={"number"}
      name={property}
      label={"Číslo požadavku"}
      value={value}
      fullWidth
      sx={{ mb: 3 }}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
};

const IssueFilterContentStatus = () => {
  const property = "statusIds";
  const dispatch = useAppDispatch();

  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const items = [
    {
      code: ISSUE_STATUS_CODE__new,
      label: getIssueStatusByCode(ISSUE_STATUS_CODE__new).label,
    },
    {
      code: ISSUE_STATUS_CODE__inProgress,
      label: getIssueStatusByCode(ISSUE_STATUS_CODE__inProgress).label,
    },
  ];

  return (
    <FormControl component="fieldset" margin="normal" fullWidth>
      <FormLabel component="legend">Stav</FormLabel>
      <FormGroup row>
        {items.map((item) => {
          const checked = value.includes(item.code);
          return (
            <FormControlLabel
              key={item.code}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    if (value.includes(item.code)) {
                      setValue(value.filter((v: any) => v !== item.code));
                    } else {
                      setValue(value.concat(item.code));
                    }
                  }}
                />
              }
              label={item.label}
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const IssueFilterContentType = () => {
  const property = "typeIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <FormControl component="fieldset" margin="normal" fullWidth>
      <FormLabel component="legend">Priorita</FormLabel>
      <FormGroup row>
        {getIssueTypes().map((item) => {
          const checked = value.includes(item.code);
          return (
            <FormControlLabel
              key={item.code}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    if (value.includes(item.code)) {
                      setValue(value.filter((v: any) => v !== item.code));
                    } else {
                      setValue(value.concat(item.code));
                    }
                  }}
                />
              }
              label={item.label}
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

const IssueFilterContentUnit = () => {
  const property = "unitIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const unitOptions = useAppSelector((state) => selectFlattenUnits(state));

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={unitOptions.filter((u) => value.includes(u.id))}
      options={unitOptions}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>&nbsp;&nbsp;
            {/* {formatAddress(option.address)} */}
            {option.subtitle || ""}
          </li>
        );
      }}
      groupBy={(option) => {
        // @ts-ignore
        return option.brand.code;
      }}
      renderGroup={(item) => {
        const { group, children } = item;
        const code = group as unknown as Pick<BrandType, "code">;
        const codeString = code as unknown as string;
        return (
          <React.Fragment key={item.key}>
            <Box sx={{ m: 1, display: "flex", alignItems: "center" }}>
              <Avatar sx={{ mx: 1 }} src={getBrandLogoUrlByBrandCode(code)} />
              <Box sx={{ fontWeight: 600 }}>{codeString}</Box>
            </Box>
            <Box sx={{ mb: 2 }}>{children}</Box>
          </React.Fragment>
        );
      }}
      getOptionLabel={(option) =>
        option.title + " " + formatAddress(option.address)
      }
      renderInput={(params) => (
        <TextField {...params} margin="normal" label={"Středisko"} />
      )}
    />
  );
};

const IssueFilterContentServiceCompany = () => {
  const property = "serviceCompanyIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const options = useAppSelector(selectServiceCompanies);

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={options.filter((u) => value.includes(u.id))}
      options={options}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} margin="normal" label={"Servisní firma"} />
      )}
    />
  );
};

const IssueFilterContentServiceTechnician = () => {
  const property = "serviceTechnicianIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const options = useAppSelector(selectServiceTechnicians);

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={options.filter((u) => value.includes(u.id))}
      options={options}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} margin="normal" label={"Servisní technik"} />
      )}
    />
  );
};

const IssueFilterContentHideIssueProgressItemType = () => {
  const property = "hideIssueProgressItemTypeIds";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  const options = [
    {
      id: ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByUnit,
      title: "Vyřízené za středisko",
    },
    {
      id: ISSUE_PROGRESS_ITEM_TYPE_CODE__markedAsDoneByServiceCompany,
      title: "Vyřízené za servisní firmu",
    },
    {
      id: ISSUE_PROGRESS_ITEM_TYPE_CODE__consideredAsDoneByHeadquarters,
      title: "Považovány za vyřízené",
    },
  ];

  return (
    <Autocomplete
      multiple={true}
      onChange={(event, newValue) => {
        if (!!newValue) {
          setValue(newValue.map((v) => v.id));
        } else {
          setValue(null);
        }
      }}
      value={options.filter((u) => value.includes(u.id))}
      options={options}
      renderOption={(props, option) => {
        const { key, ...rest } = props;
        return (
          <li key={option.id} {...rest}>
            <strong>{option.title}</strong>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label={"Skrýt požadavky, které jsou"}
        />
      )}
    />
  );
};

const IssueFilterContentShowClosed = () => {
  const property = "showClosed";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={(event) => {
              setValue(event.target.checked);
            }}
          />
        }
        label="Uzavřené požadavky"
      />
    </FormGroup>
  );
};

const IssueFilterContentShowManagedByFieldManagers = () => {
  const property = "showManagedByFieldManagers";

  const dispatch = useAppDispatch();
  const value = useAppSelector((state) =>
    selectIssueFilterProperty(state, { property: property })
  );

  const setValue = (value: any) => {
    dispatch(
      issueSlice.actions.issueFilterPropertySet({
        property: property,
        value: value,
      })
    );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={(event) => {
              setValue(event.target.checked);
            }}
          />
        }
        label="Požadavky regionálních manažerů"
      />
    </FormGroup>
  );
};

const IssueFilterContent = () => {
  const profile = useAppSelector(selectProfile);

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <IssueFilterContentNumber />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <IssueFilterContentDateFrom />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IssueFilterContentDateTo />
        </Grid>
      </Grid>

      <IssueFilterContentStatus />
      <IssueFilterContentType />
      <IssueFilterContentUnit />
      <IssueFilterContentServiceCompany />
      <IssueFilterContentServiceTechnician />
      <IssueFilterContentHideIssueProgressItemType />
      <Divider sx={{ mt: 1, mb: 1 }} />
      {profile.type === USER_TYPE_CODE__headquarters && (
        <IssueFilterContentShowManagedByFieldManagers />
      )}
      <IssueFilterContentShowClosed />
    </>
  );
};

const IssueFilter = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const filterEnabled = useAppSelector(selectIssueFilterIsEnabled);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    dispatch(issueSlice.actions.issueFilterSet(buildInitialFilter()));
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ButtonGroup
        orientation={
          useMediaQuery(theme.breakpoints.up("md")) ? "horizontal" : "vertical"
        }
        fullWidth={useMediaQuery(theme.breakpoints.up("md")) ? false : true}
      >
        <Button
          onClick={handleOpen}
          variant={"outlined"}
          color={filterEnabled ? "warning" : "primary"}
          startIcon={<FilterList />}
        >
          Filtrovat
        </Button>
        <Button
          onClick={() => {
            handleReset();
          }}
          variant={"outlined"}
          disabled={!filterEnabled}
          startIcon={<Clear />}
        >
          Zrušit filtr
        </Button>
        <Button
          onClick={() => {
            dispatch(
              issueSlice.actions.issueFilterPropertySet({
                property: "typeIds",
                value: [ISSUE_TYPE_CODE__crash],
              })
            );
          }}
          variant={"outlined"}
          startIcon={<WarningIcon />}
        >
          Zobrazit havárie
        </Button>
      </ButtonGroup>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            p: 2,
            background: (theme) => `${theme.palette.background.default}`,
            width: {
              xs: "100%",
              md: "40%",
            },
          },
        }}
      >
        <CloseButton onClick={handleClose} />

        <Button
          onClick={() => {
            handleReset();
          }}
          size={"small"}
        >
          Zrušit filtr
        </Button>

        <Divider sx={{ mt: 1, mb: 3 }} />

        <IssueFilterContent />
      </Popover>
    </>
  );
};

export default IssueFilter;
