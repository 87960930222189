import { Nullable } from "../../../../types";
import {
  Autocomplete,
  Button,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { buildUuidv4 } from "../../builders/uuidv4.builder";

export interface FormFieldOption {
  inputValue?: string;
  title: string;
  id: string;
}

type Payload = Nullable<{ property: string; value: any }>;

interface FormFieldAutocompleteProps {
  label: string;
  property: string;
  defaultOptionId: Nullable<string>;
  creatable: boolean;
  options: FormFieldOption[] | (() => Promise<Response>);
  dialog?: {
    headerText: string;
    contentText: string;
  };
  onChange: (payload: Payload[]) => any;
}

const FormFieldAutocomplete = (props: FormFieldAutocompleteProps) => {
  const {
    label,
    property,
    defaultOptionId,
    creatable = false,
    dialog = null,
    onChange,
  } = props;

  const filter = createFilterOptions<FormFieldOption>();

  const [options, setOptions] = useState<FormFieldOption[]>([]);
  const [value, setValue] = useState<Nullable<FormFieldOption>>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    title: "",
  });

  const handleDialogClose = () => {
    setDialogValue({
      title: "",
    });
    setOpen(false);
  };

  const handleSubmit = (newValue: any) => {
    setValue(newValue);
    const payload: Payload[] = [];
    payload.push({
      property,
      value: !!newValue
        ? {
            id: newValue.id === "-1" ? buildUuidv4() : newValue.id,
            title: newValue.title,
          }
        : null,
    });
    onChange(payload);
  };

  const handleDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit({ id: "-1", title: dialogValue.title });
    handleDialogClose();
  };

  const getValue = () => {
    return value;
  };

  useEffect(() => {
    if (Array.isArray(props.options)) {
      setOptions(props.options);
    } else {
      setLoading(true);
      props
        .options()
        .then((response: any) => {
          if (response.status === 200) {
            //@ts-ignore
            setOptions(
              response.data.map((d: any) => ({ id: d.id, title: d.title }))
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.options]);

  useEffect(() => {
    if (options.length > 0 && !!defaultOptionId) {
      const index = options.findIndex((o) => o.id === defaultOptionId);
      if (index > -1) {
        setValue(options[index]);
      }
    }
  }, [options, defaultOptionId]);

  return (
    <>
      <Autocomplete
        loading={loading}
        value={getValue()}
        onChange={(event: any, newValue: any) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setOpen(true);
              setDialogValue({
                title: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            setOpen(true);
            setDialogValue({
              title: newValue.inputValue,
            });
          } else {
            handleSubmit(newValue);
          }
        }}
        filterOptions={(options: any, params: any) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "" && !!dialog) {
            filtered.push({
              inputValue: params.inputValue,
              id: "-1",
              title: `${dialog.headerText}: "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={options}
        renderOption={(props: any, option: FormFieldOption) => {
          const { key, ...rest } = props;
          return (
            <li key={option.id} {...rest}>
              {option.title}
            </li>
          );
        }}
        getOptionLabel={(option: FormFieldOption | string) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderInput={(params: any) => {
          if (loading && !!defaultOptionId) {
            return (
              <TextField
                {...params}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CircularProgress color={"inherit"} sx={{ zoom: 0.5 }} />
                    </InputAdornment>
                  ),
                }}
                label={label}
              />
            );
          }
          return <TextField {...params} label={label} />;
        }}
        freeSolo={true}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
      />
      {creatable && !!dialog && (
        <Dialog open={open} onClose={handleDialogClose}>
          <form onSubmit={handleDialogSubmit}>
            <DialogTitle>{dialog.headerText}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialog.contentText}</DialogContentText>
              <TextField
                autoFocus
                sx={{ mt: 3 }}
                fullWidth={true}
                value={dialogValue.title}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    title: event.target.value,
                  })
                }
                label="Název"
                type="text"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Zrušit</Button>
              <Button type="submit">Založit</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default FormFieldAutocomplete;
